function initSkipToContent() {
    const skipBtn = document.querySelector('#skip-content')
    const rules = [
      'main [tabindex="0"]',
      'main input',
      'main button',
      'main a'
    ]
    let focusElem

    if (skipBtn) {
        skipBtn.addEventListener('click', () => {
          for (let i = 0; i < rules.length; i++){
            let element = document.querySelector(rules[i])
            if (element && isVisible(element)) {
              element.focus()
              break
            }
          }
        })
    }
}
function isVisible(elem) {
  return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
}
export {
    initSkipToContent
}