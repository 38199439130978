import '../styles/main.scss' // Our styles

import social from './social' // Social network helpers
import analytics from './analytics'
import progress from './progress'
import focusVisible from './focus-visible'

window.helpers = Object.assign({}, social, analytics, progress, focusVisible)

import { initAccessibility } from './accessibility'
import { initPlayList } from './playlist/index'
import { initEventTimer } from './event-timer'

document.addEventListener('DOMContentLoaded', (event) => {
	let notification = document.getElementById('notification')
	if (notification && notification.offsetWidth > window.innerWidth*0.9) {
		notification.nextElementSibling.style.display = "block"
	}
    initPlayList()
    initAccessibility()
	initEventTimer()
})