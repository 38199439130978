import { createPlayList } from './playlist'

function hoverVideo(event, id) {
    let player = document.querySelector(`video[data-guid='${id}']`)
    let isPlaying = player.currentTime > 0 && !player.paused && !player.ended && player.readyState > 2
    switch (event) {
        case 'play':
            player.play()
        break;
        case 'stop':
            if (isPlaying) {
                player.pause()
                player.currentTime = player.dataset.starttime
            }
        break;
    }
}

function addVideoToPlaylist(videoSrc, pageName, pageUrl, description, guid, startTime) {
    let store = JSON.parse(localStorage.getItem('playlist')) || []
    let pageDescription = description.replace(/<\/?[^>]+(>|$)/g, "")
    let isVideoInPlaylist = store.find(element => element.guid === guid) !== undefined
    if (!isVideoInPlaylist) {
        store.push({
            videoSrc,
            pageName,
            pageUrl,
            pageDescription,
            guid,
            startTime
        })
        localStorage.setItem('playlist', JSON.stringify(store))
        createPlayList() // Updates video instances in playlist dropdown
        checkPlaylistButtons(guid, true) // Replaces 'add to playlist' with 'remove from playlist' button
    }
}

function removeVideoFromPlaylist(guid) {
    event.preventDefault()
    let store = JSON.parse(localStorage.getItem('playlist')) || []
    store.forEach((item, index) => {
        if (item.guid === guid) {
            store.splice(index, 1)
        }
    })
    localStorage.setItem('playlist', JSON.stringify(store))
    createPlayList() // Updates video instances in playlist dropdown
    checkPlaylistButtons(guid, false) // Replaces 'remove from playlist' with 'add to playlist' button
}

// Switches 'add to/remove from playlist' buttons according to isInPlaylist argument
function checkPlaylistButtons(guid, isInPlaylist) {
    let currentVideo = document.querySelector('.video-player')
    let relatedVideosContainer = document.querySelector('.related-siblings')

    if (relatedVideosContainer) {
        let el = document.querySelector(`.video-chapter[data-guid="${guid}"]`)
        if (el)
            removeButtons(el, isInPlaylist)
    }
    if (currentVideo) {
        let el = document.querySelector(`.video-player[data-guid="${guid}"]`)
        if (el)
            removeButtons(el, isInPlaylist)
    }
}
function removeButtons(el, isInPlaylist){
    let removeVideoPlaylistButton = el.querySelector('.remove-video-playlist')
    let addVideoPlaylistButton = el.querySelector('.add-video-playlist')
    if (removeVideoPlaylistButton && addVideoPlaylistButton) {
        if (isInPlaylist) {
            addVideoPlaylistButton.style.display = 'none'
            removeVideoPlaylistButton.style.display = 'block'
        } else {
            addVideoPlaylistButton.style.display = 'block'
            removeVideoPlaylistButton.style.display = 'none'
        }
    }
}

function initRelatedVideosPlaylistButtons() {
    let relatedVideosContainer = document.querySelector('.related-siblings')
    if (relatedVideosContainer) {
        let store = JSON.parse(localStorage.getItem('playlist')) || []
        let relatedVideosList = Array.from(document.querySelectorAll('.video-chapter'))
        relatedVideosList.forEach( el => {
            let isInPlaylist = store.find(element => element.guid === el.dataset.guid) !== undefined
            checkPlaylistButtons(el.dataset.guid, isInPlaylist)
        })
    }
}

function initHelpers() {
    // Helpers setup
    window.helpers.hoverVideo = hoverVideo
    window.helpers.addVideoToPlaylist = addVideoToPlaylist
    window.helpers.removeVideoFromPlaylist = removeVideoFromPlaylist
}

export {
    initHelpers,
    checkPlaylistButtons,
    addVideoToPlaylist,
    removeVideoFromPlaylist,
    initRelatedVideosPlaylistButtons
}