function template(data) {
    return `<li id="${data.guid}" draggable="true" onmouseenter="helpers.hoverVideo('play', '${data.guid}')" onmouseleave="helpers.hoverVideo('stop', '${data.guid}')">
                <span class="move"><i class="fa fa-ellipsis-v"></i></span>
                <video data-guid='${data.guid}' data-page-url="${data.pageUrl}" data-page-name="${data.pageName}" data-description="${data.pageDescription}" data-starttime="${data.startTime}" muted src="${data.videoSrc}#t=${data.startTime}"></video>
                <div class="chapter-info">
                    <a href="${data.pageUrl}" class="video-name">${data.pageName}</a>
                    <span class="chapter-description">${data.pageDescription}</span>
                </div>
                <div 
                    data-guid='${data.guid}' 
                    onclick="helpers.removeVideoFromPlaylist('${data.guid}')" 
                    onkeypress="if(event.keyCode == 13){helpers.removeVideoFromPlaylist('${data.guid}')}"
                    class="delete" 
                    tabindex="0">
                    <i class="fa fa-trash-o"></i>
                </div>
            </li>`
}

function updatePlaylistCounter() {
    let playlistCounter = document.getElementById('playlist-counter')
    let counter = JSON.parse(localStorage.getItem('playlist')) || []
    if (playlistCounter) {
        playlistCounter.innerHTML = '(' + counter.length + ')'
    }
}
function insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}
function sortable(rootEl){
    let dragEl
        
    // Sorting function
    function _onDragOver(evt){
        evt.preventDefault()
        evt.stopPropagation()
        evt.dataTransfer.dropEffect = 'move'
       
        let target = evt.target.closest('li')
        if( target && target !== dragEl ){
            if (!target.previousSibling || dragEl == target.nextSibling) {
                rootEl.insertBefore(dragEl, target)
            } else  {
                rootEl.insertBefore(dragEl, target.nextSibling)
            }
        }
    }
    
    // End of sorting
    function _onDragEnd(evt){
        evt.preventDefault()

        dragEl.classList.remove('ghost')
        rootEl.removeEventListener('dragover', _onDragOver, false)
        rootEl.removeEventListener('dragend', _onDragEnd, false)
        updatePlaylist()
    }
    
    // Begining of sorting
    rootEl.addEventListener('dragstart', function (evt){
        dragEl = evt.target.closest('li') // Element that will be dragged

        evt.dataTransfer.effectAllowed = 'move'

        rootEl.addEventListener('dragover', _onDragOver, false)
        rootEl.addEventListener('dragend', _onDragEnd, false)

        setTimeout(function (){
            dragEl.classList.add('ghost')
        }, 0)
    }, false)
}

function createPlayList() {
    let store = JSON.parse(localStorage.getItem('playlist')) || []
    let playListContainer = document.getElementById('video-list')
    let playListNotification = document.querySelector('.playlist-notification')
    let playlistHtml = ''

    if (store && store.length > 0) {
        playListNotification.style.display = 'none'
    } else {
        playListNotification.style.display = 'block'
    }
    store.forEach(item => playlistHtml += template(item))
    playListContainer.innerHTML = playlistHtml

    updatePlaylistCounter()
}

function updatePlaylist() {
    let store = []
    let playListContainer = document.getElementById('video-list')
    let videos = playListContainer.querySelectorAll('video')
    for (let i = 0; i < videos.length; i++) {
      store.push({
        videoSrc: videos[i].src,
        pageName: videos[i].dataset.pageName,
        pageUrl: videos[i].dataset.pageUrl,
        pageDescription: videos[i].dataset.pageDescription,
        guid: videos[i].dataset.guid,
        startTime: videos[i].dataset.startTime
      })
    }
    localStorage.setItem('playlist', JSON.stringify(store))
}

export {
    createPlayList,
    updatePlaylist,
    updatePlaylistCounter,
    sortable
}