function initEventTimer() {
    const timerNode = document.getElementById('event-timer')

    if (timerNode) {
        let [hours, minutes, seconds] = timerNode.dataset.initialTime.split('.')[0].split(':').map(x => parseInt(x))

        timerNode.innerText = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

        const timer = setInterval(() => {
            if (seconds > 0) {
                seconds -= 1
            } else if (minutes > 0) {
                seconds = 59
                minutes -= 1
            } else {
                clearInterval(timer)
            }
            timerNode.innerText = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        }, 1000)
    }
}

export {
    initEventTimer
}
