function trackCTA(event) {
    const elem = event.target
    const ctaName = elem.dataset.cta

    if (window._paq) {
        _paq.push(['trackEvent', 'CTA', 'Click', ctaName])
    }
}

function trackVideoPlay() {
    if (window._paq) {
        _paq.push(['trackEvent', 'Video', 'Play'])
    }
}

function trackVideoProgress(progress) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Video', 'Progress', progress])
    }
}

function trackMapPinClick(pinName) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Map', 'Pin Click', pinName])
    }
}

function trackMapImage(pinName) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Map', 'Image View', pinName])
    }
}

function trackMapVideoView(pinName) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Map', 'Video View', pinName])
    }
}

function trackMapImage360View(pinName) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Map', 'Image 360 View', pinName])
    }
}

function trackMap3dImageView(pinName) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Map', '3d Image Matterport View', pinName])
    }
}

export default {
    trackCTA,
    trackVideoPlay,
    trackVideoProgress,
    trackMapPinClick,
    trackMapImage,
    trackMapVideoView,
    trackMapImage360View,
    trackMap3dImageView
}