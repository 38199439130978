import {openShareList} from './accessibility/chapter';

function openFacebookShare(appId, href, text) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Share', 'Click', 'Facebook'])
    }
    // return window.open(`https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${href}`,
    //                    'Facebook Share',
    //                    'width=600,height=600')
    return window.open(`http://www.facebook.com/sharer/sharer.php?u=${href}&quote=${encodeURIComponent(text)}`,
                       'Facebook Share',
                       'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
}

function openTwitterShare(url, text) {
    if (window._paq) {
        _paq.push(['trackEvent', 'Share', 'Click', 'Twitter'])
    }
    return window.open(`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(text)}`,
                       'Twitter Share',
                       'width=600,height=400')
}

export default {
    openFacebookShare,
    openTwitterShare,
    openShareList
}