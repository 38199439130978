function openShareList(classShareContainer) {
    let listElement = document.querySelector(`.${classShareContainer}`)
    if (listElement.classList.contains('active')) {
        listElement.classList.remove('active')
    } else {
        listElement.classList.add('active')
        listElement.querySelector('.socialicon').focus();
    }
}

function socialShare(twitterShare, facebookShare, listElement, shareElement) {
    if (!twitterShare || !facebookShare || !listElement || !shareElement) {
        return
    }
    twitterShare.addEventListener('focusout', (e) => {
        setTimeout(function(){
            if (document.activeElement !== facebookShare ) {
                listElement.classList.remove('active')
            }
        }, 0)
    })
    facebookShare.addEventListener('focusout', (e) => {
        setTimeout(function(){
            if ( document.activeElement !== twitterShare ) {
                listElement.classList.remove('active')
            }
        }, 0)
    })
    document.addEventListener('click', function(e) {
        if (listElement.classList.contains('active') && !shareElement.contains(e.target)) {
            listElement.classList.remove('active')
        }
    })
}

function initSocialShare() {
    let shareElement = document.querySelector('.share')
    let listElement = document.querySelector('.player-share-container') || document.querySelector('.image-share-container')
    let facebookShare = document.querySelector('.facebook-share')
    let twitterShare = document.querySelector('.twitter-share')
    socialShare(twitterShare, facebookShare, listElement, shareElement)
}

function initFooterSocialShare() {
    let shareElement = document.querySelector('.footer .share')
    let listElement = document.querySelector('.share-content')
    let facebookShare = document.querySelector('.footer .share .facebook-share')
    let twitterShare = document.querySelector('.footer .share .twitter-share')
    socialShare(twitterShare, facebookShare, listElement, shareElement)
}

export {
    openShareList,
    initSocialShare,
    initFooterSocialShare
}