let navBlocks = []
let navBlocksLinks = {}

function initNavbarKeyboardNavigation () {
    navBlocks = Array.from(document.querySelectorAll('.player-navigation-block'))

    navBlocks.forEach(elem => {
        let elemNavIndex = navBlocks.findIndex(item => item.isSameNode(elem))
        let elemNavLinks = Array.from(elem.querySelectorAll('.nav-link'))
        let dropdowns = Array.from(elem.querySelectorAll('.player-navigation-dropdown'))
        let activeChannel = document.querySelector('.player-navigation-block.active')

        navBlocksLinks[elemNavIndex] = elemNavLinks

        let elemLinkIndex = 0

        dropdowns.forEach(elem => {
            // Adds highlight to the channel
            elem.addEventListener('mouseover', event => {
                let parent = event.currentTarget.parentNode
                if (!parent.classList.contains('active')){
                    parent.className += " active"
                }
            })
            // Removes highlight from previous channel
            elem.addEventListener('mouseout', event => {
                if (event.currentTarget.parentNode !== activeChannel){
                    event.target.closest('.player-navigation-block').classList.remove('active')
                }
            })
        })
        elem.addEventListener('keydown', event => {


            if (event.isComposing || event.keyCode === 229) {
                return
            }

            // Left arrow moves to the previous menu category
            if (event.keyCode === 37) {
                let prevNavIndex = elemNavIndex > 0 ? elemNavIndex - 1 : navBlocks.length - 1
                let prevFirstLink = navBlocksLinks[prevNavIndex][0]
                
                prevFirstLink.focus()
            }

            // Right arrow moves to the next menu category
            if (event.keyCode === 39) {
                let nextNavIndex = elemNavIndex < navBlocks.length - 1 ? elemNavIndex + 1 : 0 
                let nextFirstLink = navBlocksLinks[nextNavIndex][0]
                
                nextFirstLink.focus()
            }

            // Up arrow moves to the previous menu category item
            if (event.keyCode === 38) {
                let prevLinkIndex = elemLinkIndex > 0 ? elemLinkIndex - 1 : navBlocksLinks[elemNavIndex].length - 1
                let prevLink = navBlocksLinks[elemNavIndex][prevLinkIndex]

                prevLink.focus()

                elemLinkIndex = prevLinkIndex
            }

            // Down arrow moves to the next menu category item
            if (event.keyCode === 40) {
                let nextLinkIndex = elemLinkIndex < navBlocksLinks[elemNavIndex].length - 1 ? elemLinkIndex + 1 : 0
                let nextLink = navBlocksLinks[elemNavIndex][nextLinkIndex]

                nextLink.focus()

                elemLinkIndex = nextLinkIndex
            }
        })
    })
}

export { initNavbarKeyboardNavigation }