// import { initLastVisitedUrl, trackUrl } from './popup'

let experiencePages = []
let visitedPages = null
let currentPage = undefined
let progressBar = null
let siblings = null

function loadProgress() {
    let storedVisits = localStorage.getItem('pageVisits')

    visitedPages = JSON.parse(storedVisits) || []
}

function disableSiblings() {
    siblings = Array.from(document.querySelectorAll('a[data-guid]'))
    siblings.forEach((element) => {
        let guid = element.getAttribute('data-guid')
        if ( !visitedPages.includes(guid) ) {
            element.setAttribute("disabled", "true")
        }
      })
}

function intersection(setA, setB) {
    let _intersection = new Set()
    for (let elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem)
        }
    }
    return _intersection
}

function displayProgress() {
    let visited = new Set(visitedPages)
    let experience = new Set(experiencePages)
    let progress = intersection(visited, experience)
    
    progressBar.value = progress.size
}

function initExperience(page, experience, experience_id) {
    currentPage = page
    experiencePages = experience

    progressBar = document.getElementById('experience-progress')

    if (progressBar) {
        loadProgress()

        disableSiblings()
        // initLastVisitedUrl(page, experience, experience_id)
        displayProgress()

        if (!visitedPages.includes(currentPage)) {
            ++progressBar.value
        }
        
    }
}

export default {
    initExperience
}