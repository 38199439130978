let bgVideo = undefined
let bgVideoBtn = undefined
let bgVideoBtnIcon = undefined
let playing = false

function initBgVideo () {
    bgVideo = document.getElementById('bgvid')
    bgVideoBtn = document.getElementById('btn-bgvid')
    bgVideoBtnIcon = document.getElementById('btn-bgvid-icon')

    if (bgVideo) {
        bgVideo.addEventListener('play', () => {
            bgVideoBtnIcon.classList.replace('fa-play', 'fa-pause')
            playing = true
        })

        bgVideo.addEventListener('pause', () => {
            bgVideoBtnIcon.classList.replace('fa-pause', 'fa-play')
            playing = false
        })

        bgVideoBtn.addEventListener('click', () => {
            if (playing) {
                bgVideo.pause()
            } else {
                bgVideo.play()
            }
        })
    }
}

export {
    initBgVideo
}