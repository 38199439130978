import { initNavbarKeyboardNavigation } from './navbar'
import { initBgVideo } from './bgvideo'
import { initSocialShare, initFooterSocialShare } from './chapter'
import { initSkipToContent } from './skipContent'

function initAccessibility () {
    initNavbarKeyboardNavigation()
    initBgVideo()
    initSocialShare()
    initFooterSocialShare()
    initSkipToContent()
}

export { initAccessibility }