import { initHelpers, initRelatedVideosPlaylistButtons } from './helpers'
import { createPlayList, sortable } from './playlist'

function initPlayList() {
    let playListContainer = document.getElementById('video-list')

    if (playListContainer) {
	    createPlayList()
	    sortable(playListContainer)
    }
    initHelpers()
    initRelatedVideosPlaylistButtons()
}
export {
    initPlayList
}